import React, {useEffect, useRef, useState} from 'react';
import {
  Box,
  Grid,
  Card,
  Button,
  useTheme,
  FormLabel,
  FormControl,
  FormHelperText,
} from '@material-ui/core';

import {useField} from '@unform/core';

interface IProps {
  name: string;
  label: string;
  value?: number;
  options: number[];
}

export const Rating: React.FC<IProps> = ({name, label, options}) => {
  const {fieldName, defaultValue, registerField, error} = useField(name);
  const [selectValue, setSelectedValue] = useState(defaultValue);

  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => selectValue,
      setValue: (_, value) => setSelectedValue(value),
    });
  }, [fieldName, selectValue, registerField]);

  const handleChange = (value: number): void => {
    setSelectedValue(value);
  };

  return (
    <>
      <Card
        variant="outlined"
        style={{
          backgroundColor: 'inherit',
          borderColor: error ? theme.palette.error.main : undefined,
        }}>
        <Box px={2} py={2}>
          <FormControl component="fieldset" error={!!error}>
            <Grid container spacing={1} ref={inputRef}>
              <Grid item xs={12}>
                <FormLabel component="legend">{label}</FormLabel>
              </Grid>
              {options.map((option) => (
                <Grid key={option} item>
                  <Button
                    disableElevation
                    color={option === selectValue ? 'primary' : 'default'}
                    variant={option === selectValue ? 'contained' : 'outlined'}
                    onClick={() =>
                      handleChange(selectValue === option ? -1 : option)
                    }>
                    {option}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </FormControl>
        </Box>
      </Card>
      {error && (
        <Grid item xs={12}>
          <Box ml={1.75}>
            <FormHelperText error={!!error}>{error}</FormHelperText>
          </Box>
        </Grid>
      )}
    </>
  );
};
