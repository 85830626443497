import React, {
  useRef,
  useMemo,
  Fragment,
  useState,
  useEffect,
  useCallback,
} from 'react';
import {
  Box,
  Grid,
  Link,
  Button,
  Divider,
  MenuItem,
  IconButton,
  Typography,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';
import {Form} from '@unform/web';
import InputMask from 'react-input-mask';
import {FormHandles} from '@unform/core';
import {format, addHours} from 'date-fns';
import {ObjectShape} from 'yup/lib/object';
import ReCAPTCHA from 'react-google-recaptcha';
import {useNavigate, useParams} from 'react-router-dom';
import {CloudUpload, Create, Delete, Visibility} from '@material-ui/icons';
import {
  Header,
  Select,
  Checkbox,
  TextField,
  RatingForm,
  InfoTooltip,
  MultiSelect,
  SplashScreen,
} from 'shared/components';
import {useResponse} from 'shared/hooks';
import {
  AvailabilitiesService,
  IScheduleAvailability,
} from 'shared/services/api/ScheduleAvailabilityService';
import stringCapitalize from 'shared/utils/stringCapitalize';
import getValidationErrors from 'shared/utils/getValidationErrors';
import setValidationErrors from 'shared/utils/setValidationErrors';
import {AvailableSchedule} from 'shared/components/AvailableSchedule';
import {
  ETipoDeCampo,
  FormService,
  IFormFields,
  IOffer,
  IResponseForm,
  IValidationFields,
} from 'shared/services/api/FormService';
import {AddressService, IAddress} from 'shared/services/api/AddressService';

import {useStyles} from './styles';
import {Alert} from '@material-ui/lab';
import {
  timezone,
  handleFormatText,
  handleDataFormat,
  handleMaxColumns,
  createDefaultNameId,
  handleFormatInputTextData,
  handleTermoLGDPTextFormatter,
} from 'shared/utils';

interface IInputVisibleProps {
  value?: string;
  visible: boolean;
}

export interface IInputVisible {
  [key: string]: IInputVisibleProps;
}

export interface ICepActive {
  [key: string]: boolean;
}

interface IPredefined {
  establishment: string;
  course: string;
  cycle: string;
  shift: string;
  additional1: string;
  additional2: string;
}

export const FormPage: React.FC = () => {
  const [recaptcha, setRecaptcha] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [address, setAddress] = useState<IAddress[]>([]);
  const [isInvalideCep, setIsInvalideCep] = useState(false);
  const [cepActive, setCepActive] = useState<ICepActive>({});
  const [inputVisible, setInputVisible] = useState<IInputVisible>({});
  const [formValidation, setFormValidation] = useState({} as ObjectShape);
  const [availabilities, setAvailabilities] = useState<IScheduleAvailability[]>(
    [],
  );
  const [isCheckboxError, setIsCheckboxError] = useState(false);
  const [isRecaptchaError, setIsRecaptchaError] = useState(false);
  const [emailsErrorsId, setEmailsErrorsId] = useState<string[]>([]);
  const [predefined, setPredefined] = useState<IPredefined>({
    establishment: '',
    course: '',
    cycle: '',
    shift: '',
    additional1: '',
    additional2: '',
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isResponseError, setIsResponseError] = useState(false);

  const classes = useStyles();
  const navigate = useNavigate();
  const {
    crypto,
    idLead = '',
    idCampaign = '',
    leadCrypto = '',
  } = useParams<'crypto' | 'idLead' | 'idCampaign' | 'leadCrypto'>();

  const {
    isInternal,
    getData,
    getDataLead,
    getInternalData,
    getInternalEditData,
    postInternalData,
    postData,
    putData,
    putDataLead,
    setCrypto,
    idEstablishment,
    idCourse,
    idCycle,
    idShift,
    form,
    termoLGPD,
    dataHoraAceiteTermoLgpd,
    setIsTermoLGPDAtivo,
    offers,
    personalization,
    course,
    cycle,
    establishment,
    shift,
    loading,
    isError,
    message,
    isEditing,
    permissionEdit,
    useZipCode,
  } = useResponse();

  const formRef = useRef<FormHandles>(null);

  const handleGetAddress = useCallback(
    async (cep: string, index: number) => {
      setIsLoading(true);

      if (!cep.includes('_') && cep.length >= 8) {
        const response = await AddressService.getAddress(cep);

        const addressCopy: IAddress[] = address;
        if (response) {
          addressCopy[index] = response;
          setAddress([...addressCopy]);
          setCepActive((state) => (state = {...state, [index]: true}));

          if (
            addressCopy[index].bairro === '' ||
            addressCopy[index].uf === '' ||
            addressCopy[index].localidade === '' ||
            addressCopy[index].logradouro === ''
          ) {
            setCepActive((state) => (state = {...state, [index]: false}));
          }

          setIsInvalideCep(false);
        } else {
          addressCopy[index] = {
            cep,
            bairro: '',
            logradouro: '',
            localidade: '',
            uf: '',
          };
          setAddress([...addressCopy]);

          setCepActive((state) => (state = {...state, [index]: false}));
          setIsInvalideCep(true);
        }
      }
      setIsLoading(false);
    },
    [address],
  );

  const handleGetFile = useCallback(async (idArquivo: string) => {
    try {
      setIsLoading(true);
      const response = await FormService.getFile(idArquivo);
      if (response.sucesso) {
        const idArquivo: HTMLAnchorElement = document.createElement('a');

        idArquivo.href = `data:application/octet-stream;base64,${response.data.conteudo}`;
        idArquivo.download = response.data.nomeOriginal;
        idArquivo.click();
      } else {
        console.error('Erro ao tentar carregar o arquivo.');
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleChangeRecaptcha = useCallback((value: string | null) => {
    if (value) {
      setRecaptcha(value);
      setIsRecaptchaError(false);
    }
  }, []);

  const handleValidateEmail = useCallback(
    (idCampo: string, validacoes: IValidationFields[]) => {
      if (emailsErrorsId.some((value) => value === idCampo)) {
        return validacoes.some((value) => value.idCondicaoParaValidacao === 2);
      }
    },
    [emailsErrorsId],
  );

  const handleGetSchedules = useCallback(async (idOffer: string) => {
    const response = await AvailabilitiesService.getAvailabilities(idOffer);

    const availabilities = response?.map(
      (availability: IScheduleAvailability) =>
        ({
          ...availability,
          iniciaEm: format(
            addHours(new Date(availability.iniciaEm), timezone()),
            "yyyy-MM-dd'T'HH:mm",
          ),
          terminaEm: format(
            addHours(new Date(availability.terminaEm), timezone()),
            "yyyy-MM-dd'T'HH:mm",
          ),
        } as IScheduleAvailability),
    );

    if (availabilities) {
      setAvailabilities([...availabilities]);
    }
  }, []);

  const handleGetAddressValues = useCallback(
    (id: number, index: number) => {
      if (cepActive) {
        switch (id) {
          case 9:
            return address[index]?.logradouro || '';
          case 12:
            return address[index]?.uf || '';
          case 13:
            return address[index]?.localidade || '';
          case 14:
            return address[index]?.bairro || '';
          default:
            return undefined;
        }
      }
      return undefined;
    },
    [cepActive, address],
  );

  const handleSetAdressValues = useCallback(
    (idPredefinido: number, index: number, target) => {
      const addressCopy: IAddress[] = address;

      switch (idPredefinido) {
        case 9:
          addressCopy[index].logradouro = target.value;
          break;
        case 12:
          addressCopy[index].uf = target.value;
          break;
        case 13:
          addressCopy[index].localidade = target.value;
          break;
        case 14:
          addressCopy[index].bairro = target.value;
          break;
        default:
          break;
      }

      setAddress([...addressCopy]);
    },
    [address],
  );

  const courseFiltered = useMemo(
    () =>
      course.filter((i) => i.idEstabelecimento === predefined.establishment),
    [course, predefined.establishment],
  );

  const cycleFiltered = useCallback(
    (idEstabelecimento: string, idCurso: string) =>
      cycle.filter(
        (i) =>
          i.idEstabelecimento === idEstabelecimento && i.idCurso === idCurso,
      ),
    [cycle],
  );

  const getCurrentIdOffer = useCallback(
    (
      idEstabelecimento: string,
      idCurso: string,
      idCiclo?: string,
      idTurno?: string,
    ) => {
      const hasCycle = cycleFiltered(idEstabelecimento, idCurso).some(
        (cycle) => cycle.nome !== null,
      );
      const hasShift = idTurno && idTurno !== '';

      const findOffer = (offers: IOffer[]) => {
        if (hasCycle && idCiclo) {
          return offers.find(
            (i) => i.idCiclo === idCiclo && i.idTurno === idTurno,
          );
        }
        return offers.find((i) =>
          i.idEstabelecimento === idEstabelecimento &&
          i.idCurso === idCurso &&
          i.idCiclo === null &&
          i.idTurno === idTurno
            ? idTurno
            : null,
        );
      };

      if (hasShift) {
        return findOffer(offers.filter((i) => i.idTurno === idTurno))?.id;
      }
      return findOffer(offers)?.id;
    },
    [cycleFiltered, offers],
  );

  const initialOffer = useMemo(() => {
    const hasCycle = cycleFiltered(
      predefined.establishment,
      predefined.course,
    ).some((cycle) => cycle.nome !== null);
    const hasShift = idShift !== undefined;

    const findOffer = (offers: IOffer[]) => {
      if (hasCycle && idCycle) {
        return offers.find((i) => i.idCiclo === idCycle);
      }
      return offers.find(
        (i) =>
          i.idEstabelecimento === idEstablishment && i.idCurso === idCourse,
      );
    };

    if (hasShift) {
      return offers.find(
        (item) =>
          item.id ===
          findOffer(offers.filter((i) => i.idTurno === idShift))?.id,
      );
    }
    return offers.find((item) => item.id === findOffer(offers)?.id);
  }, [
    cycleFiltered,
    idCourse,
    idCycle,
    idEstablishment,
    idShift,
    offers,
    predefined.course,
    predefined.establishment,
  ]);

  const offerSelected = useMemo(
    () =>
      offers.find(
        (item) =>
          item.id ===
          getCurrentIdOffer(
            predefined.establishment,
            predefined.course,
            predefined.cycle,
            predefined.shift,
          ),
      ),
    [
      getCurrentIdOffer,
      offers,
      predefined.course,
      predefined.cycle,
      predefined.establishment,
      predefined.shift,
    ],
  );

  const isInitialOfferChanged = useMemo(
    () =>
      (idEstablishment && predefined.establishment !== idEstablishment) ||
      (idCourse && predefined.course !== idCourse) ||
      (idCycle && predefined.cycle !== idCycle) ||
      (idShift && predefined.shift !== idShift),

    [idEstablishment, idCourse, idCycle, idShift, predefined],
  );

  const shiftFiltered = useMemo(() => {
    if (
      cycleFiltered(predefined.establishment, predefined.course).some(
        (cycle) => cycle.nome !== null,
      ) &&
      predefined.cycle
    ) {
      return shift.filter(
        (i) =>
          i.idEstabelecimento === predefined.establishment &&
          i.idCurso === predefined.course &&
          i.idCiclo === predefined.cycle,
      );
    } else {
      return shift.filter(
        (i, index, self) =>
          i.idEstabelecimento === predefined.establishment &&
          i.idCurso === predefined.course &&
          index === self.findIndex((obj) => obj.id === i.id),
      );
    }
  }, [
    cycleFiltered,
    predefined.cycle,
    predefined.course,
    predefined.establishment,
    shift,
  ]);

  const handleTermoLGPDCheckboxValidate = useCallback(() => {
    if (!termoLGPD.concordaComTermosECondicoes) {
      setIsCheckboxError(true);
    } else {
      setIsCheckboxError(false);
    }
  }, [termoLGPD.concordaComTermosECondicoes]);

  const handleOptPredefined = useCallback(
    (field?: IFormFields) => {
      if (!field) return [];

      switch (field.idCampoPredefinido) {
        case 2:
          return establishment;
        case 3:
          return courseFiltered;
        case 4:
          return cycleFiltered(predefined.establishment, predefined.course);
        case 5:
          return shiftFiltered;
        case 6:
          return offerSelected?.complementos1DaOferta || [];
        case 7:
          return offerSelected?.complementos2DaOferta || [];
        default:
          return field.opcoes;
      }
    },
    [
      establishment,
      courseFiltered,
      cycleFiltered,
      predefined.establishment,
      predefined.course,
      shiftFiltered,
      offerSelected?.complementos1DaOferta,
      offerSelected?.complementos2DaOferta,
    ],
  );

  const handleSubmitRequest = async (respostas: IResponseForm[]) => {
    const tipoControleDeVaga =
      offerSelected && offerSelected.controleVagas
        ? offerSelected.controleVagas.valorVagasRestante !== 0
          ? 1
          : 2
        : null;

    const valorControleDeVaga =
      offerSelected && offerSelected.controleVagas
        ? offerSelected.controleVagas.valorVagasRestante !== 0
          ? offerSelected.controleVagas.numeroDeVagas -
            offerSelected.controleVagas.valorVagasRestante
          : offerSelected.controleVagas.valorListaDeEsperaAtual
        : null;

    let response = null;
    try {
      if (offerSelected?.id) {
        if (isInternal) {
          if (isEditing) {
            response = await putData(idLead, {
              idLeadOrigem: 0,
              idOferta: offerSelected.id,
              tipoControleDeVaga,
              valorControleDeVaga,
              respostas,
              concordaComTermosECondicoes:
                termoLGPD.concordaComTermosECondicoes,
              textoTermoLgpd: termoLGPD.textoTermoLgpd,
            });
          } else {
            response = await postInternalData({
              idLeadOrigem: 1,
              idOferta: offerSelected.id,
              tipoControleDeVaga,
              valorControleDeVaga,
              respostas,
              concordaComTermosECondicoes:
                termoLGPD.concordaComTermosECondicoes,
              textoTermoLgpd: termoLGPD.textoTermoLgpd,
            });
          }
        } else if (leadCrypto) {
          response = await putDataLead(idLead, {
            idOferta: offerSelected.id,
            tipoControleDeVaga,
            valorControleDeVaga,
            idLeadOrigem: 0,
            respostas,
            concordaComTermosECondicoes: termoLGPD.concordaComTermosECondicoes,
            textoTermoLgpd: termoLGPD.textoTermoLgpd,
          });
        } else {
          response = await postData(recaptcha, {
            idLeadOrigem: 2,
            idOferta: offerSelected.id,
            tipoControleDeVaga,
            valorControleDeVaga,
            respostas,
            concordaComTermosECondicoes: termoLGPD.concordaComTermosECondicoes,
            textoTermoLgpd: termoLGPD.textoTermoLgpd,
          });
        }
      }
      navigate('/confirmacao', {state: response});
    } catch (error: any) {
      if (typeof error === 'object') {
        setIsResponseError(true);
        if (error.message.includes('Error: ')) {
          setErrorMessage(error.message.replace('Error: ', ''));
        } else {
          setErrorMessage(error.message);
        }

        setTimeout(() => {
          setIsResponseError(false);
        }, 5000);
      }
    }
  };

  const handleOnSubmit = async (data: any) => {
    try {
      formRef.current?.setErrors({});

      const respostas: IResponseForm[] = [];
      let shiftName = '';
      let scheduleUsage;

      form.map((item) =>
        item.campos.map((field) => {
          if (field.idTipoDeCampo === ETipoDeCampo.AGENDA) {
            scheduleUsage = availabilities.filter(
              (i) =>
                `${format(
                  new Date(i.iniciaEm),
                  'dd/MM/yyyy HH:mm',
                )} às ${format(new Date(i.terminaEm), 'HH:mm')}` ===
                data[stringCapitalize(field.nomeCampo, field.idCampo)],
            )[0]?.idDisponibilidadeDeAgenda;

            if (!scheduleUsage) {
              setSubmitted(true);
              return;
            }
          }

          if (field.idCampoPredefinido === 5) {
            shiftName = stringCapitalize(field.nomeCampo, field.idCampo);
          }

          respostas.push({
            idCampoDaFicha: String(field.idCampo),
            valor:
              field.idTipoDeCampo === ETipoDeCampo.DATA ||
              field.idTipoDeCampo === ETipoDeCampo.LISTA_COM_SELECAO_UNICA ||
              field.idTipoDeCampo === ETipoDeCampo.NUMERO_INTEIRO ||
              field.idTipoDeCampo === ETipoDeCampo.NUMERO_DECIMAL ||
              field.idTipoDeCampo === ETipoDeCampo.TEXTO ||
              field.idTipoDeCampo === ETipoDeCampo.TEXTO_LONGO ||
              field.idTipoDeCampo === ETipoDeCampo.BARRA_DE_PROGRESSO
                ? data[stringCapitalize(field.nomeCampo, field.idCampo)]
                : field.idTipoDeCampo === ETipoDeCampo.AGENDA
                ? availabilities.filter(
                    (i) =>
                      `${format(
                        new Date(i.iniciaEm),
                        'dd/MM/yyyy HH:mm',
                      )} às ${format(new Date(i.terminaEm), 'HH:mm')}` ===
                      data[stringCapitalize(field.nomeCampo, field.idCampo)],
                  )[0].idDisponibilidadeDeAgenda
                : field.idTipoDeCampo === ETipoDeCampo.DOCUMENTO &&
                  !inputVisible[field.idCampo].visible
                ? inputVisible[field.idCampo].value
                : '',
            valorChecado:
              field.idTipoDeCampo === ETipoDeCampo.CAIXA_DE_SELECAO
                ? data[stringCapitalize(field.nomeCampo, field.idCampo)]
                : false,
            nomeArquivo:
              field.idTipoDeCampo === ETipoDeCampo.DOCUMENTO &&
              !!data[stringCapitalize(field.nomeCampo, field.idCampo)]
                ? data[stringCapitalize(field.nomeCampo, field.idCampo)].name
                : field.idTipoDeCampo === ETipoDeCampo.DOCUMENTO &&
                  !inputVisible[field.idCampo].visible
                ? field.resposta?.nomeArquivo
                : '',
            conteudoArquivo:
              field.idTipoDeCampo === ETipoDeCampo.DOCUMENTO &&
              !!data[stringCapitalize(field.nomeCampo, field.idCampo)]
                ? data[
                    `${stringCapitalize(field.nomeCampo, field.idCampo)}-file`
                  ]
                : '',
            opcoes:
              field.idTipoDeCampo === ETipoDeCampo.LISTA_COM_SELECAO_MULTIPLA
                ? data[stringCapitalize(field.nomeCampo, field.idCampo)]?.map(
                    (opt: string) => {
                      return {id: opt};
                    },
                  )
                : data[stringCapitalize(field.nomeCampo, field.idCampo)] ===
                  false
                ? []
                : field.idTipoDeCampo === ETipoDeCampo.LISTA_COM_SELECAO_UNICA
                ? [
                    {
                      id: data[
                        stringCapitalize(field.nomeCampo, field.idCampo)
                      ],
                    },
                  ]
                : [],
          });
        }),
      );

      setIsRecaptchaError(recaptcha === '');

      if (shiftFiltered.length === 0) {
        delete formValidation[shiftName];
      }

      const schema = Yup.object().shape(formValidation);
      await schema.validate(data, {
        abortEarly: false,
      });

      if (!recaptcha && !isInternal) {
        if (termoLGPD.disponibilizarTermoDeAceiteParaLead && !isEditing) {
          if (!termoLGPD.concordaComTermosECondicoes) {
            setIsCheckboxError(true);
          }
        }
        return;
      }
      setSubmitted(false);

      if (termoLGPD.disponibilizarTermoDeAceiteParaLead && !isEditing) {
        if (!termoLGPD.concordaComTermosECondicoes) {
          setIsCheckboxError(true);
        } else {
          if (emailsErrorsId.length === 0) {
            handleSubmitRequest(respostas);
          }
        }
      } else {
        if (isEditing) {
          setIsTermoLGPDAtivo(true);
        }
        if (emailsErrorsId.length === 0) {
          handleSubmitRequest(respostas);
        }
      }
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err as Yup.ValidationError);
        formRef.current?.setErrors(errors);

        handleTermoLGPDCheckboxValidate();
      }
    }
  };

  const handleChangeForm = useCallback(() => {
    form.map((item) => {
      item.campos
        .filter((field) => field.idTipoDeCampo === ETipoDeCampo.DOCUMENTO)
        .map((field) => {
          const nomeCampo = stringCapitalize(field.nomeCampo, field.idCampo);
          const value = formRef.current?.getFieldValue(nomeCampo);

          if (value) {
            const reader = new FileReader();

            reader.onload = () => {
              const {result} = reader;

              formRef.current?.setFieldValue(
                `${nomeCampo}-file`,
                String(result).split(',')[1],
              );
            };

            reader.readAsDataURL(value);
          }
        });
      item.campos
        .filter(
          (field) =>
            field.idTipoDeCampo === ETipoDeCampo.TEXTO ||
            field.idTipoDeCampo === ETipoDeCampo.TEXTO_LONGO,
        )
        .map((field) => {
          const nomeCampo = stringCapitalize(field.nomeCampo, field.idCampo);
          let value = formRef.current?.getFieldValue(nomeCampo);
          if (
            value &&
            field.validacoes.some(
              (validacao) => validacao.idCondicaoParaValidacao === 2,
            )
          ) {
            value = value.replaceAll(' ', '');
            const especialCharVerification = /[^a-zA-Z0-9._+@-]/;

            if (especialCharVerification.test(value.replace('@', ''))) {
              if (!emailsErrorsId.some((value) => value === field.idCampo)) {
                setEmailsErrorsId((prev) => [...prev, field.idCampo]);
              }
            } else {
              setEmailsErrorsId((prev) =>
                prev.filter((value) => value !== field.idCampo),
              );
            }
            value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

            formRef.current?.setFieldValue(nomeCampo, value);
          }

          if (value && field.formatarTexto) {
            formRef.current?.setFieldValue(
              nomeCampo,
              handleFormatText(value, field.formatarTexto || ''),
            );
          }
        });

      item.campos
        .filter((field) => field.idTipoDeCampo === ETipoDeCampo.DATA)
        .map((field) => {
          const nomeCampo = stringCapitalize(field.nomeCampo, field.idCampo);
          const value = formRef.current?.getFieldValue(nomeCampo);
          const newValue = handleFormatInputTextData(value as string);
          formRef.current?.setFieldValue(nomeCampo, newValue);
        });
    });
  }, [emailsErrorsId, form]);

  const handleDocumentChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = event.target.files;

      if (fileList && fileList.length > 0) {
        const bytes = fileList[0].size;
        const fileSize = (bytes / (1024 * 1024)).toFixed(1);

        if (Number(fileSize) >= 5) {
          const nomePadronizado = createDefaultNameId(
            event.target.parentNode?.querySelector('fieldset span')
              ?.textContent || '',
          );

          const id = event.target.getAttribute('id');

          const nomeFinal = nomePadronizado + id;

          formRef.current?.setErrors({
            [nomeFinal]: 'Arquivo deve ter no máximo 5MB',
          });

          event.target.value = '';
          return;
        } else {
          formRef.current?.setErrors({});
        }
      }
    },
    [],
  );

  const handleChange = useCallback(
    (
      currentValue: string,
      field?: IFormFields,
      modoRecursivo?: IPredefined,
    ) => {
      const ref = formRef.current;

      if (!field || !ref) return;

      const atualizarCampo = (valor: string, campo?: IFormFields) => {
        const nomeCampo = stringCapitalize(
          campo?.nomeCampo || '',
          campo?.idCampo || '',
        );
        ref.setFieldValue(nomeCampo, valor);
      };

      // atualizando o campo atual
      atualizarCampo(currentValue, field);

      const camposDaOferta =
        form.find((item) => item.idSecaoPredefinida === 1)?.campos || [];

      const buscarCampo = (id: number) =>
        camposDaOferta.find((campo) => campo.idCampoPredefinido === id);

      const campoCurso = buscarCampo(3);
      const campoCiclo = buscarCampo(4);
      const campoTurno = buscarCampo(5);
      const complementar1 = buscarCampo(6);
      const complementar2 = buscarCampo(7);

      const shiftOptions = () => {
        if (
          cycleFiltered(
            modoRecursivo?.establishment || predefined.establishment,
            modoRecursivo?.course || predefined.course,
          ).some((cycle) => cycle.nome !== null) &&
          currentValue
        ) {
          return shift.filter(
            (i) =>
              (i.idEstabelecimento === modoRecursivo?.establishment ||
                i.idEstabelecimento === predefined.establishment) &&
              (i.idCurso === modoRecursivo?.course ||
                i.idCurso === predefined.course) &&
              i.idCiclo === currentValue,
          );
        }
        return shift.filter(
          (i) =>
            i.idCurso === predefined.course &&
            i.idEstabelecimento === predefined.establishment,
        );
      };

      switch (field.idCampoPredefinido) {
        // Estabelecimento
        case 2:
          {
            const courseOptions = course.filter(
              (i) => i.idEstabelecimento === currentValue,
            );

            if (courseOptions.length === 1) {
              handleChange(String(courseOptions[0].id), campoCurso);
            } else {
              // Resetando campos no form
              atualizarCampo('', campoCurso);
              atualizarCampo('', campoCiclo);
              atualizarCampo('', campoTurno);
              atualizarCampo('', complementar1);
              atualizarCampo('', complementar2);

              setPredefined(
                (state) =>
                  (state = {
                    ...state,
                    establishment: String(currentValue),
                    course: '',
                    cycle: '',
                    shift: '',
                    additional1: '',
                    additional2: '',
                  }),
              );
            }
          }

          break;
        // Curso
        case 3:
          {
            const cycleOptions = cycle.filter(
              (i) =>
                (i.idEstabelecimento === modoRecursivo?.establishment ||
                  i.idEstabelecimento === predefined.establishment) &&
                i.idCurso === currentValue,
            );
            const hasOffer = getCurrentIdOffer(
              modoRecursivo?.establishment || predefined.establishment,
              currentValue,
            );

            if (
              cycleOptions.length === 0 &&
              shiftOptions().length === 1 &&
              !hasOffer
            ) {
              handleChange(String(shiftOptions()[0].id), campoTurno, {
                ...predefined,
                course: currentValue,
                shift: String(shiftOptions()[0].id),
              });
            } else if (cycleOptions.length === 1 && !hasOffer) {
              handleChange(String(cycleOptions[0].id), campoCiclo, {
                ...predefined,
                course: currentValue,
                cycle: String(cycleOptions[0].id),
              });
            } else {
              atualizarCampo('', campoCiclo);
              atualizarCampo('', campoTurno);
              atualizarCampo('', complementar1);
              atualizarCampo('', complementar2);

              setPredefined((state) => ({
                ...(modoRecursivo || state),
                course: String(currentValue),
                cycle: '',
                shift: '',
                additional1: '',
                additional2: '',
              }));
            }
          }
          break;
        // Ciclo
        case 4: {
          const hasOffer = getCurrentIdOffer(
            modoRecursivo?.establishment || predefined.establishment,
            modoRecursivo?.course || predefined.course,
            currentValue,
          );

          if (shiftOptions().length === 1 && !hasOffer) {
            handleChange(String(shiftOptions()[0].id), campoTurno, {
              ...predefined,
              course: modoRecursivo?.course || predefined.course,
              cycle: currentValue,
              shift: String(shiftOptions()[0].id),
            });
          } else {
            atualizarCampo('', campoTurno);
            atualizarCampo('', complementar1);
            atualizarCampo('', complementar2);

            setPredefined((state) => ({
              ...(modoRecursivo || state),
              cycle: String(currentValue),
              shift: '',
              additional1: '',
              additional2: '',
            }));
          }

          break;
        }
        // Turno
        case 5:
          {
            atualizarCampo('', complementar1);
            atualizarCampo('', complementar2);

            setPredefined((state) => ({
              ...(modoRecursivo || state),
              shift: String(currentValue),
              additional1: '',
              additional2: '',
            }));
          }
          break;
        case 6: {
          setPredefined(
            (state) =>
              (state = {
                ...state,
                additional1: String(currentValue),
              }),
          );
          break;
        }
        case 7: {
          setPredefined(
            (state) =>
              (state = {
                ...state,
                additional2: String(currentValue),
              }),
          );
          break;
        }
      }
    },
    [course, cycle, cycleFiltered, form, getCurrentIdOffer, predefined, shift],
  );

  const isCreatingLead = useMemo(
    () => (crypto || idCampaign) && !leadCrypto && !idLead,
    [crypto, idCampaign, idLead, leadCrypto],
  );

  const initialData = useMemo(() => {
    let auxIdEstabelecimento = '';
    let auxIdCurso = '';
    let auxIdCiclo = '';
    let auxIdTurno = '';

    let aux = {};
    form.forEach((item) => {
      item?.campos?.forEach((field) => {
        if (field.ativo) {
          if (field.idCampoPredefinido === 2) {
            auxIdEstabelecimento =
              idEstablishment?.split('.')[1] === '0'
                ? establishment.length > 1
                  ? ''
                  : establishment[0].id
                : idEstablishment || '';

            aux = {
              ...aux,
              [stringCapitalize(field.nomeCampo, field.idCampo)]:
                auxIdEstabelecimento,
            };
          } else if (field.idCampoPredefinido === 3) {
            if (isCreatingLead) {
              const courseOptions = course.filter(
                (i) => i.idEstabelecimento === auxIdEstabelecimento,
              );

              auxIdCurso =
                courseOptions.length === 1 ? courseOptions[0].id : '';
            } else {
              auxIdCurso = idCourse ? idCourse : '';
            }

            aux = {
              ...aux,
              [stringCapitalize(field.nomeCampo, field.idCampo)]: auxIdCurso,
            };
          } else if (field.idCampoPredefinido === 4) {
            if (isCreatingLead) {
              const cycleOptions = cycle.filter(
                (i) =>
                  i.idEstabelecimento === auxIdEstabelecimento &&
                  i.idCurso === auxIdCurso,
              );
              const hasOffer = getCurrentIdOffer(
                auxIdEstabelecimento,
                auxIdCurso,
              );

              auxIdCiclo =
                cycleOptions.length === 1 && !hasOffer
                  ? cycleOptions[0].id
                  : '';
            } else {
              auxIdCiclo = idCycle ? idCycle : '';
            }

            aux = {
              ...aux,
              [stringCapitalize(field.nomeCampo, field.idCampo)]: auxIdCiclo,
            };
          } else if (field.idCampoPredefinido === 5) {
            if (isCreatingLead) {
              const shiftOptions = () => {
                if (
                  cycleFiltered(auxIdEstabelecimento, auxIdCurso).some(
                    (cycle) => cycle.nome !== null,
                  ) &&
                  auxIdCiclo
                ) {
                  return shift.filter(
                    (i) =>
                      i.idEstabelecimento === auxIdEstabelecimento &&
                      i.idCurso === auxIdCurso &&
                      i.idCiclo === auxIdCiclo,
                  );
                }
                return shift.filter(
                  (i) =>
                    i.idCurso === auxIdCurso &&
                    i.idEstabelecimento === auxIdEstabelecimento,
                );
              };

              const hasOffer = getCurrentIdOffer(
                auxIdEstabelecimento,
                auxIdCurso,
                auxIdCiclo,
              );

              auxIdTurno =
                shiftOptions().length === 1 && !hasOffer
                  ? shiftOptions()[0].id
                  : '';
            } else {
              auxIdTurno = idShift ? idShift : '';
            }
            aux = {
              ...aux,
              [stringCapitalize(field.nomeCampo, field.idCampo)]: auxIdTurno,
            };
          } else if (field.resposta) {
            if (field.idCampo === field.resposta.idCampoDaFicha) {
              switch (field.idTipoDeCampo) {
                case ETipoDeCampo.CAIXA_DE_SELECAO:
                  aux = {
                    ...aux,
                    [stringCapitalize(field.nomeCampo, field.idCampo)]:
                      field.resposta.valorChecado,
                  };
                  break;
                case ETipoDeCampo.DOCUMENTO:
                  aux = {
                    ...aux,
                    [stringCapitalize(field.nomeCampo, field.idCampo)]: '',
                  };
                  break;
                case ETipoDeCampo.LISTA_COM_SELECAO_UNICA: {
                  if (field.idCampoPredefinido === 6) {
                    const optionsLength =
                      field.resposta?.opcoes.length > 0
                        ? field.resposta?.opcoes.length - 1
                        : 0;
                    setPredefined(
                      (state) =>
                        (state = {
                          ...state,
                          additional1: String(
                            field.resposta?.opcoes[optionsLength]?.valor || '',
                          ),
                        }),
                    );
                  }
                  if (field.idCampoPredefinido === 7) {
                    const optionsLength =
                      field.resposta?.opcoes.length > 0
                        ? field.resposta?.opcoes.length - 1
                        : 0;
                    setPredefined(
                      (state) =>
                        (state = {
                          ...state,
                          additional2: String(
                            field.resposta?.opcoes[optionsLength]?.valor || '',
                          ),
                        }),
                    );
                  }
                  const optionsLength =
                    field.resposta?.opcoes.length > 0
                      ? field.resposta?.opcoes.length - 1
                      : 0;
                  aux = {
                    ...aux,
                    [stringCapitalize(field.nomeCampo, field.idCampo)]:
                      field.resposta.opcoes[optionsLength]?.valor || '',
                  };
                  break;
                }
                case ETipoDeCampo.LISTA_COM_SELECAO_MULTIPLA:
                  if (field.resposta.opcoes)
                    aux = {
                      ...aux,
                      [stringCapitalize(field.nomeCampo, field.idCampo)]:
                        field.resposta.opcoes.map((i) => i.valor),
                    };
                  break;
                case ETipoDeCampo.BARRA_DE_PROGRESSO:
                  aux = {
                    ...aux,
                    [stringCapitalize(field.nomeCampo, field.idCampo)]: Number(
                      field.resposta.valor,
                    ),
                  };
                  break;
                default:
                  aux = {
                    ...aux,
                    [stringCapitalize(field.nomeCampo, field.idCampo)]:
                      field.resposta.valor,
                  };

                  break;
              }
            }
          } else {
            if (field.idTipoDeCampo === ETipoDeCampo.BARRA_DE_PROGRESSO) {
              aux = {
                ...aux,
                [stringCapitalize(field.nomeCampo, field.idCampo)]: -1,
              };
            } else {
              aux = {
                ...aux,
                [stringCapitalize(field.nomeCampo, field.idCampo)]: '',
              };
            }
          }
        }
      });
    });

    setPredefined((state) => ({
      ...state,
      establishment: auxIdEstabelecimento,
      course: auxIdCurso,
      cycle: auxIdCiclo,
      shift: auxIdTurno,
    }));
    return aux;
  }, [
    course,
    cycle,
    cycleFiltered,
    establishment,
    form,
    getCurrentIdOffer,
    idCourse,
    idCycle,
    idEstablishment,
    idShift,
    isCreatingLead,
    shift,
  ]);

  useEffect(() => {
    if (form) {
      setFormValidation(
        setValidationErrors(
          form,
          inputVisible,
          establishment,
          courseFiltered,
          cycleFiltered(predefined.establishment, predefined.course),
          shiftFiltered,
          offerSelected?.complementos1DaOferta || [],
          offerSelected?.complementos2DaOferta || [],
        ),
      );
    }

    formRef.current?.setErrors({});
  }, [form, inputVisible, predefined]);

  useEffect(() => {
    if (crypto) {
      setCrypto(crypto);
      getData(crypto);
    } else if (idCampaign && idLead) {
      getInternalEditData(idCampaign, idLead);
    } else if (leadCrypto) {
      setCrypto(leadCrypto);
      getDataLead(leadCrypto);
    } else {
      getInternalData(idCampaign);
    }
  }, [crypto, idCampaign, idLead]);

  useEffect(() => {
    setPredefined((state) => ({
      ...state,
      establishment:
        idEstablishment?.split('.')[1] === '0'
          ? establishment.length > 1
            ? ''
            : establishment[0].id
          : idEstablishment || '',
      course: idCourse || '',
      cycle: idCycle || '',
      shift: idShift || '',
    }));
  }, [idCourse, idCycle, idEstablishment, idShift]);

  useEffect(() => {
    if (offerSelected?.id) {
      handleGetSchedules(offerSelected.id);
    }
  }, [handleGetSchedules, offerSelected?.id]);

  useEffect(() => {
    form.map((i) =>
      i.campos.map((field) => {
        if (field.idTipoDeCampo === ETipoDeCampo.DOCUMENTO) {
          if (field.resposta?.valor) {
            setInputVisible(
              (state) =>
                (state = {
                  ...state,
                  [field.idCampo]: {
                    visible: false,
                    value: field.resposta?.valor,
                  },
                }),
            );
          } else {
            setInputVisible(
              (state) =>
                (state = {
                  ...state,
                  [field.idCampo]: {
                    visible: true,
                    value: '',
                  },
                }),
            );
          }
        }
      }),
    );
  }, [form]);

  if (loading) {
    return <SplashScreen />;
  }

  if (message && message.length > 0) {
    return <SplashScreen message={message} />;
  }

  if (isError) {
    return <SplashScreen error />;
  }

  return (
    <Box maxWidth="100%">
      {isLoading && (
        <div className={classes.overlay}>
          <CircularProgress />
        </div>
      )}

      {isResponseError && (
        <Alert
          variant="filled"
          severity="error"
          style={{
            width: 'fit-content',
            position: 'fixed',
            right: 24,
            top: 16,
            zIndex: 90,
            marginLeft: 16,
          }}
          onClick={() => setIsResponseError(false)}>
          {errorMessage}
        </Alert>
      )}

      {!isInternal && <Header personalization={personalization} />}

      <Box marginTop={2} padding={3}>
        <Form
          ref={formRef}
          onSubmit={handleOnSubmit}
          initialData={initialData}
          onChange={handleChangeForm}>
          <Grid container spacing={3}>
            {!isInternal && personalization.instrucaoParaCabecalhoFormulario && (
              <Box
                width="100%"
                textAlign="center"
                dangerouslySetInnerHTML={{
                  __html:
                    personalization.instrucaoParaCabecalhoFormulario.replaceAll(
                      'class',
                      'className',
                    ),
                }}
              />
            )}
            {form.map((item, indexSec) => (
              <Fragment key={item.idSecao}>
                {item.descricao && (
                  <Grid item xs={12}>
                    {item.descricao}
                  </Grid>
                )}
                {item.campos.length > 0 &&
                  item.apresentarNoFormulario &&
                  item.campos.filter(
                    (i) => i.ativo && (i.disponivelParaLead || isInternal),
                  ).length > 0 && (
                    <Grid item xs={12}>
                      {item.ehFichaComplementar ? (
                        <Box display={'flex'} alignItems={'center'}>
                          <Typography variant="h5">
                            <strong>{item.nomeSecao}</strong>
                          </Typography>
                          <Box marginLeft={0.7} marginTop={0.5}>
                            <Typography variant="body1">
                              (Ficha Complementar)
                            </Typography>
                          </Box>
                        </Box>
                      ) : (
                        <Typography variant="h5">
                          <strong>{item.nomeSecao}</strong>
                        </Typography>
                      )}
                    </Grid>
                  )}
                {item.campos.map((field) => (
                  <Fragment key={field.idCampo}>
                    {field.ativo && (
                      <>
                        {(field.disponivelParaLead || isInternal) &&
                          field.idTipoDeCampo === ETipoDeCampo.AGENDA && (
                            <Grid
                              item
                              xs={12}
                              sm={handleMaxColumns(item.maximoColunas)}>
                              <AvailableSchedule
                                label={
                                  field.obrigatorio
                                    ? `${field.nomeCampo} *`
                                    : field.nomeCampo
                                }
                                error={field.obrigatorio && submitted}
                                helperText={
                                  field.obrigatorio &&
                                  submitted &&
                                  'Campo requerido.'
                                }
                                disabled={!permissionEdit}
                                name={stringCapitalize(
                                  field.nomeCampo,
                                  field.idCampo,
                                )}
                                availabilities={availabilities}
                                cycleFiltered={cycleFiltered(
                                  predefined.establishment,
                                  predefined.course,
                                )}
                                predefinedCycle={predefined.cycle}
                                predefinedCourse={predefined.course}
                              />
                            </Grid>
                          )}
                        {(field.disponivelParaLead || isInternal) &&
                          field.idTipoDeCampo ===
                            ETipoDeCampo.CAIXA_DE_SELECAO && (
                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    name={stringCapitalize(
                                      field.nomeCampo,
                                      field.idCampo,
                                    )}
                                    disabled={!permissionEdit}
                                    color="primary"
                                  />
                                }
                                label={
                                  <>
                                    {field.obrigatorio
                                      ? `${field.nomeCampo} *`
                                      : field.nomeCampo}{' '}
                                    {field.textoDeAjuda && (
                                      <InfoTooltip title={field.textoDeAjuda} />
                                    )}
                                  </>
                                }
                              />
                            </Grid>
                          )}
                        {(field.disponivelParaLead || isInternal) &&
                          field.idTipoDeCampo === ETipoDeCampo.DATA && (
                            <Grid
                              item
                              xs={12}
                              sm={handleMaxColumns(item.maximoColunas)}>
                              <TextField
                                id={String(field.idCampo)}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                InputProps={{
                                  inputProps: {
                                    min: '0001-01-01',
                                    max: '9999-12-31',
                                  },
                                }}
                                disabled={!permissionEdit}
                                label={
                                  field.obrigatorio
                                    ? `${field.nomeCampo} *`
                                    : field.nomeCampo
                                }
                                name={stringCapitalize(
                                  field.nomeCampo,
                                  field.idCampo,
                                )}
                                type="date"
                                helper={
                                  field.textoDeAjuda
                                    ? field.textoDeAjuda
                                    : undefined
                                }
                              />
                            </Grid>
                          )}
                        {(field.disponivelParaLead ||
                          isInternal ||
                          leadCrypto) &&
                          field.idTipoDeCampo === ETipoDeCampo.DOCUMENTO && (
                            <Grid
                              item
                              xs={12}
                              alignItems="center"
                              sm={handleMaxColumns(item.maximoColunas)}>
                              {(leadCrypto || isEditing) &&
                              !inputVisible[field.idCampo].visible ? (
                                <Grid
                                  wrap="nowrap"
                                  container
                                  alignItems="center"
                                  xs={12}
                                  spacing={1}>
                                  <Grid item xs={12} md={12}>
                                    <TextField
                                      onChange={handleDocumentChange}
                                      fullWidth
                                      id={String(field.idCampo)}
                                      disabled
                                      InputLabelProps={{shrink: true}}
                                      label={
                                        field.obrigatorio
                                          ? `${field.nomeCampo} *`
                                          : field.nomeCampo
                                      }
                                      name={`${stringCapitalize(
                                        field.nomeCampo,
                                        field.idCampo,
                                      )}-edit`}
                                      value={field.resposta?.nomeArquivo}
                                      InputProps={{
                                        inputProps: {
                                          accept: 'image/*,application/pdf',
                                          style: {
                                            cursor: 'pointer',
                                          },
                                        },
                                        endAdornment: (
                                          <InfoTooltip title="PNG/JPEG/GIF/PDF de no máximo 5MB">
                                            <IconButton
                                              disabled
                                              color="primary"
                                              component="span"
                                              aria-label="upload">
                                              <CloudUpload />
                                            </IconButton>
                                          </InfoTooltip>
                                        ),
                                      }}
                                    />
                                  </Grid>
                                  <Grid>
                                    <IconButton
                                      color="primary"
                                      component="span"
                                      disabled={!permissionEdit}
                                      onClick={() => {
                                        setInputVisible(
                                          (state) =>
                                            (state = {
                                              ...state,
                                              [field.idCampo]: {
                                                visible: true,
                                                value: '',
                                              },
                                            }),
                                        );
                                      }}>
                                      <Delete />
                                    </IconButton>
                                  </Grid>
                                  <Grid>
                                    <IconButton
                                      color="primary"
                                      component="span"
                                      disabled={!permissionEdit}
                                      onClick={() => {
                                        setInputVisible(
                                          (state) =>
                                            (state = {
                                              ...state,
                                              [field.idCampo]: {
                                                visible: true,
                                                value: '',
                                              },
                                            }),
                                        );
                                      }}>
                                      <Create />
                                    </IconButton>
                                  </Grid>
                                  {leadCrypto ? (
                                    <></>
                                  ) : (
                                    <Grid>
                                      <IconButton
                                        color="primary"
                                        component="span"
                                        disabled={!permissionEdit}
                                        onClick={() =>
                                          field.resposta?.valor &&
                                          handleGetFile(field.resposta.valor)
                                        }>
                                        <Visibility />
                                      </IconButton>
                                    </Grid>
                                  )}
                                </Grid>
                              ) : (
                                <>
                                  <TextField
                                    onChange={handleDocumentChange}
                                    type="file"
                                    disabled={!permissionEdit}
                                    id={String(field.idCampo)}
                                    InputLabelProps={{shrink: true}}
                                    label={
                                      field.obrigatorio
                                        ? `${field.nomeCampo} *`
                                        : field.nomeCampo
                                    }
                                    name={stringCapitalize(
                                      field.nomeCampo,
                                      field.idCampo,
                                    )}
                                    helper={
                                      field.textoDeAjuda
                                        ? field.textoDeAjuda
                                        : undefined
                                    }
                                    InputProps={{
                                      inputProps: {
                                        accept: 'image/*,application/pdf',
                                        style: {
                                          cursor: 'pointer',
                                        },
                                      },
                                      endAdornment: (
                                        <InfoTooltip title="PNG/JPEG/GIF/PDF de no máximo 5MB">
                                          <IconButton
                                            color="primary"
                                            component="span"
                                            aria-label="upload">
                                            <CloudUpload />
                                          </IconButton>
                                        </InfoTooltip>
                                      ),
                                    }}
                                  />
                                  <TextField
                                    style={{display: 'none'}}
                                    disabled
                                    id={`${String(field.idCampo)}-file`}
                                    name={`${stringCapitalize(
                                      field.nomeCampo,
                                      field.idCampo,
                                    )}-file`}
                                  />
                                </>
                              )}
                            </Grid>
                          )}
                        {(field.disponivelParaLead ||
                          isInternal ||
                          leadCrypto) &&
                          field.idTipoDeCampo ===
                            ETipoDeCampo.LISTA_COM_SELECAO_UNICA && (
                            <Grid
                              item
                              xs={12}
                              sm={handleMaxColumns(item.maximoColunas)}>
                              {idEstablishment &&
                              field.idCampoPredefinido === 2 ? (
                                <TextField
                                  name={stringCapitalize(
                                    field.nomeCampo,
                                    field.idCampo,
                                  )}
                                  label={
                                    field.obrigatorio
                                      ? `${field.nomeCampo} *`
                                      : field.nomeCampo
                                  }
                                  disabled={!permissionEdit}
                                  helper={
                                    field.textoDeAjuda
                                      ? field.textoDeAjuda
                                      : undefined
                                  }
                                  onChange={({target}) => {
                                    handleChange(target.value, field);
                                  }}
                                  select>
                                  {handleOptPredefined(field)?.map((opt) => (
                                    <MenuItem key={opt.id} value={opt.id}>
                                      {opt.nome}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              ) : idCourse && field.idCampoPredefinido === 3 ? (
                                <TextField
                                  name={stringCapitalize(
                                    field.nomeCampo,
                                    field.idCampo,
                                  )}
                                  label={
                                    field.obrigatorio
                                      ? `${field.nomeCampo} *`
                                      : field.nomeCampo
                                  }
                                  disabled={!permissionEdit}
                                  helper={
                                    field.textoDeAjuda
                                      ? field.textoDeAjuda
                                      : undefined
                                  }
                                  value={predefined.course}
                                  onChange={({target}) =>
                                    handleChange(target.value, field)
                                  }
                                  select>
                                  {handleOptPredefined(field)?.map((opt) => (
                                    <MenuItem key={opt.id} value={opt.id}>
                                      {opt.nome}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              ) : (idShift && field.idCampoPredefinido === 3) ||
                                leadCrypto ? (
                                <Select
                                  name={stringCapitalize(
                                    field.nomeCampo,
                                    field.idCampo,
                                  )}
                                  label={
                                    field.obrigatorio &&
                                    handleOptPredefined(field)?.length > 0
                                      ? `${field.nomeCampo} *`
                                      : field.nomeCampo
                                  }
                                  helper={
                                    field.textoDeAjuda
                                      ? field.textoDeAjuda
                                      : undefined
                                  }
                                  onChange={({target}) =>
                                    handleChange(target.value, field)
                                  }
                                  select>
                                  {handleOptPredefined(field)?.map((opt) => (
                                    <MenuItem key={opt.id} value={opt.id}>
                                      {opt.nome}
                                    </MenuItem>
                                  ))}
                                </Select>
                              ) : (
                                <Select
                                  name={stringCapitalize(
                                    field.nomeCampo,
                                    field.idCampo,
                                  )}
                                  disabled={
                                    (field.idCampoPredefinido === 4 &&
                                      cycleFiltered(
                                        predefined.establishment,
                                        predefined.course,
                                      ).every(
                                        (cycle) => cycle.nome === null,
                                      )) ||
                                    handleOptPredefined(field)?.length === 0 ||
                                    !permissionEdit
                                  }
                                  label={
                                    field.idCampoPredefinido === 4 &&
                                    cycleFiltered(
                                      predefined.establishment,
                                      predefined.course,
                                    ).every((cycle) => cycle.nome === null) ? (
                                      <i>Nenhum ciclo para ser selecionado</i>
                                    ) : field.obrigatorio &&
                                      handleOptPredefined(field)?.length > 0 ? (
                                      `${field.nomeCampo} *`
                                    ) : (
                                      field.nomeCampo
                                    )
                                  }
                                  helper={
                                    field.textoDeAjuda
                                      ? field.textoDeAjuda
                                      : undefined
                                  }
                                  onChange={({target}) => {
                                    handleChange(target.value, field);
                                  }}>
                                  {handleOptPredefined(field)?.map((opt) => (
                                    <MenuItem key={opt.id} value={opt.id}>
                                      {opt.nome}
                                    </MenuItem>
                                  ))}
                                </Select>
                              )}
                            </Grid>
                          )}
                        {(field.disponivelParaLead || isInternal) &&
                          field.idTipoDeCampo ===
                            ETipoDeCampo.LISTA_COM_SELECAO_MULTIPLA && (
                            <Grid
                              item
                              xs={12}
                              sm={handleMaxColumns(item.maximoColunas)}>
                              <MultiSelect
                                id={String(field.idCampo)}
                                label={
                                  field.obrigatorio
                                    ? `${field.nomeCampo} *`
                                    : field.nomeCampo
                                }
                                disabled={!permissionEdit}
                                helper={
                                  field.textoDeAjuda
                                    ? field.textoDeAjuda
                                    : undefined
                                }
                                name={stringCapitalize(
                                  field.nomeCampo,
                                  field.idCampo,
                                )}>
                                {handleOptPredefined(field)?.map((opt) => (
                                  <MenuItem key={opt.id} value={opt.id}>
                                    {opt.nome}
                                  </MenuItem>
                                ))}
                              </MultiSelect>
                            </Grid>
                          )}
                        {(field.disponivelParaLead || isInternal) &&
                          field.idTipoDeCampo ===
                            ETipoDeCampo.NUMERO_INTEIRO && (
                            <Grid
                              item
                              xs={12}
                              sm={handleMaxColumns(item.maximoColunas)}>
                              {!isEditing &&
                              field.mascara &&
                              field.idTipoMascara ? (
                                <>
                                  <InputMask
                                    defaultValue={field.resposta?.valor}
                                    mask={field.mascara}
                                    id={String(field.idCampo)}
                                    disabled={!permissionEdit}
                                    onBlur={({target}) =>
                                      field.idCampoPredefinido === 8 &&
                                      handleGetAddress(target.value, indexSec)
                                    }>
                                    {() => (
                                      <TextField
                                        type="number"
                                        inputProps={{
                                          min: '1.0',
                                          step: '0.1',
                                        }}
                                        id={String(field.idCampo)}
                                        defaultValue={field.resposta?.valor}
                                        label={
                                          field.obrigatorio
                                            ? `${field.nomeCampo} *`
                                            : field.nomeCampo
                                        }
                                        disabled={!permissionEdit}
                                        helper={
                                          field.textoDeAjuda
                                            ? field.textoDeAjuda
                                            : undefined
                                        }
                                        name={stringCapitalize(
                                          field.nomeCampo,
                                          field.idCampo,
                                        )}>
                                        {handleOptPredefined(field)?.map(
                                          (opt) => (
                                            <MenuItem
                                              key={opt.id}
                                              value={opt.id}>
                                              {opt.nome}
                                            </MenuItem>
                                          ),
                                        )}
                                      </TextField>
                                    )}
                                  </InputMask>
                                </>
                              ) : (
                                <TextField
                                  type="number"
                                  inputProps={{
                                    min: '1',
                                    step: '1',
                                    max: '99',
                                  }}
                                  id={String(field.idCampo)}
                                  defaultValue={field.resposta?.valor}
                                  label={
                                    field.obrigatorio
                                      ? `${field.nomeCampo} *`
                                      : field.nomeCampo
                                  }
                                  disabled={!permissionEdit}
                                  helper={
                                    field.textoDeAjuda
                                      ? field.textoDeAjuda
                                      : undefined
                                  }
                                  name={stringCapitalize(
                                    field.nomeCampo,
                                    field.idCampo,
                                  )}>
                                  {handleOptPredefined(field)?.map((opt) => (
                                    <MenuItem key={opt.id} value={opt.id}>
                                      {opt.nome}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            </Grid>
                          )}
                        {(field.disponivelParaLead || isInternal) &&
                          field.idTipoDeCampo ===
                            ETipoDeCampo.NUMERO_DECIMAL && (
                            <Grid
                              item
                              xs={12}
                              sm={handleMaxColumns(item.maximoColunas)}>
                              {!isEditing &&
                              field.mascara &&
                              field.idTipoMascara ? (
                                <>
                                  <InputMask
                                    defaultValue={field.resposta?.valor}
                                    mask={field.mascara}
                                    id={String(field.idCampo)}
                                    disabled={!permissionEdit}
                                    onBlur={({target}) =>
                                      field.idCampoPredefinido === 8 &&
                                      handleGetAddress(target.value, indexSec)
                                    }>
                                    {() => (
                                      <TextField
                                        type="number"
                                        inputProps={{
                                          min: '1.0',
                                          step: '0.1',
                                        }}
                                        id={String(field.idCampo)}
                                        defaultValue={field.resposta?.valor}
                                        label={
                                          field.obrigatorio
                                            ? `${field.nomeCampo} *`
                                            : field.nomeCampo
                                        }
                                        disabled={!permissionEdit}
                                        helper={
                                          field.textoDeAjuda
                                            ? field.textoDeAjuda
                                            : undefined
                                        }
                                        name={stringCapitalize(
                                          field.nomeCampo,
                                          field.idCampo,
                                        )}>
                                        {handleOptPredefined(field)?.map(
                                          (opt) => (
                                            <MenuItem
                                              key={opt.id}
                                              value={opt.id}>
                                              {opt.nome}
                                            </MenuItem>
                                          ),
                                        )}
                                      </TextField>
                                    )}
                                  </InputMask>
                                </>
                              ) : (
                                <TextField
                                  type="number"
                                  inputProps={{
                                    min: '1.0',
                                    step: '0.1',
                                  }}
                                  id={String(field.idCampo)}
                                  defaultValue={field.resposta?.valor}
                                  label={
                                    field.obrigatorio
                                      ? `${field.nomeCampo} *`
                                      : field.nomeCampo
                                  }
                                  disabled={!permissionEdit}
                                  helper={
                                    field.textoDeAjuda
                                      ? field.textoDeAjuda
                                      : undefined
                                  }
                                  name={stringCapitalize(
                                    field.nomeCampo,
                                    field.idCampo,
                                  )}>
                                  {handleOptPredefined(field)?.map((opt) => (
                                    <MenuItem key={opt.id} value={opt.id}>
                                      {opt.nome}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            </Grid>
                          )}
                        {(field.disponivelParaLead || isInternal) &&
                          field.idTipoDeCampo === ETipoDeCampo.TEXTO && (
                            <Grid
                              item
                              xs={12}
                              sm={handleMaxColumns(item.maximoColunas)}>
                              {!isEditing &&
                              field.mascara &&
                              field.idTipoMascara ? (
                                <>
                                  <InputMask
                                    defaultValue={field.resposta?.valor}
                                    mask={field.mascara}
                                    id={String(field.idCampo)}
                                    disabled={!permissionEdit}
                                    onBlur={({target}) =>
                                      field.idCampoPredefinido === 8 &&
                                      handleGetAddress(target.value, indexSec)
                                    }>
                                    {() => (
                                      <TextField
                                        label={
                                          field.obrigatorio
                                            ? `${field.nomeCampo} *`
                                            : field.nomeCampo
                                        }
                                        helper={
                                          field.textoDeAjuda
                                            ? field.textoDeAjuda
                                            : field.idCampoPredefinido === 8 &&
                                              isInvalideCep &&
                                              !cepActive[indexSec]
                                            ? 'CEP inválido'
                                            : handleValidateEmail(
                                                field.idCampo,
                                                field.validacoes,
                                              )
                                            ? 'Digito inválido'
                                            : undefined
                                        }
                                        name={stringCapitalize(
                                          field.nomeCampo,
                                          field.idCampo,
                                        )}
                                        disabled={!permissionEdit}
                                        isError={handleValidateEmail(
                                          field.idCampo,
                                          field.validacoes,
                                        )}
                                      />
                                    )}
                                  </InputMask>
                                  {field.idCampoPredefinido === 8 &&
                                    useZipCode.usaIntegracaoCep && (
                                      <Link
                                        target="_blank"
                                        className={classes.zipCode}
                                        href={useZipCode.linkPesquisaCep}>
                                        Não sei informar meu CEP
                                      </Link>
                                    )}
                                </>
                              ) : Object.keys(cepActive).length &&
                                (field.idCampoPredefinido === 9 ||
                                  field.idCampoPredefinido === 12 ||
                                  field.idCampoPredefinido === 13 ||
                                  field.idCampoPredefinido === 14) ? (
                                <TextField
                                  id={String(field.idCampo)}
                                  InputLabelProps={{shrink: true}}
                                  disabled={
                                    cepActive[indexSec] || !permissionEdit
                                  }
                                  label={
                                    field.obrigatorio
                                      ? `${field.nomeCampo} *`
                                      : field.nomeCampo
                                  }
                                  value={handleGetAddressValues(
                                    field.idCampoPredefinido,
                                    indexSec,
                                  )}
                                  onChange={(e) => {
                                    handleSetAdressValues(
                                      field.idCampoPredefinido,
                                      indexSec,
                                      e.target,
                                    );
                                  }}
                                  helper={
                                    field.textoDeAjuda
                                      ? field.textoDeAjuda
                                      : handleValidateEmail(
                                          field.idCampo,
                                          field.validacoes,
                                        )
                                      ? 'Digito inválido'
                                      : undefined
                                  }
                                  name={stringCapitalize(
                                    field.nomeCampo,
                                    field.idCampo,
                                  )}
                                  isError={handleValidateEmail(
                                    field.idCampo,
                                    field.validacoes,
                                  )}
                                />
                              ) : isEditing && field.idTipoMascara ? (
                                <>
                                  <InputMask
                                    mask={field.mascara}
                                    id={String(field.idCampo)}
                                    disabled={!permissionEdit}
                                    defaultValue={field.resposta?.valor}
                                    onBlur={({target}) =>
                                      field.idCampoPredefinido === 8 &&
                                      handleGetAddress(target.value, indexSec)
                                    }>
                                    {() => (
                                      <TextField
                                        disabled={!permissionEdit}
                                        label={
                                          field.obrigatorio
                                            ? `${field.nomeCampo} *`
                                            : field.nomeCampo
                                        }
                                        helper={
                                          field.textoDeAjuda
                                            ? field.textoDeAjuda
                                            : field.idCampoPredefinido === 8 &&
                                              isInvalideCep &&
                                              !cepActive[indexSec]
                                            ? 'CEP inválido'
                                            : handleValidateEmail(
                                                field.idCampo,
                                                field.validacoes,
                                              )
                                            ? 'Digito inválido'
                                            : undefined
                                        }
                                        name={stringCapitalize(
                                          field.nomeCampo,
                                          field.idCampo,
                                        )}
                                        isError={handleValidateEmail(
                                          field.idCampo,
                                          field.validacoes,
                                        )}
                                      />
                                    )}
                                  </InputMask>
                                  {field.idCampoPredefinido === 8 &&
                                    useZipCode.usaIntegracaoCep && (
                                      <Link
                                        target="_blank"
                                        className={classes.zipCode}
                                        href={useZipCode.linkPesquisaCep}>
                                        Não sei informar meu CEP
                                      </Link>
                                    )}
                                </>
                              ) : (
                                <>
                                  <TextField
                                    id={String(field.idCampo)}
                                    disabled={!permissionEdit}
                                    label={
                                      field.obrigatorio
                                        ? `${field.nomeCampo} *`
                                        : field.nomeCampo
                                    }
                                    onBlur={({target}) =>
                                      field.idCampoPredefinido === 8 &&
                                      handleGetAddress(target.value, indexSec)
                                    }
                                    helper={
                                      field.textoDeAjuda
                                        ? field.textoDeAjuda
                                        : field.idCampoPredefinido === 8 &&
                                          isInvalideCep &&
                                          !cepActive[indexSec]
                                        ? 'CEP inválido'
                                        : handleValidateEmail(
                                            field.idCampo,
                                            field.validacoes,
                                          )
                                        ? 'Digito inválido'
                                        : undefined
                                    }
                                    name={stringCapitalize(
                                      field.nomeCampo,
                                      field.idCampo,
                                    )}
                                    error={
                                      (field.idCampoPredefinido === 8 &&
                                        isInvalideCep) ||
                                      (field.obrigatorio && submitted)
                                    }
                                    isError={handleValidateEmail(
                                      field.idCampo,
                                      field.validacoes,
                                    )}
                                  />
                                  {field.idCampoPredefinido === 8 &&
                                    useZipCode.usaIntegracaoCep && (
                                      <Link
                                        target="_blank"
                                        className={classes.zipCode}
                                        href={useZipCode.linkPesquisaCep}>
                                        Não sei informar meu CEP
                                      </Link>
                                    )}
                                </>
                              )}
                            </Grid>
                          )}
                        {(field.disponivelParaLead || isInternal) &&
                          field.idTipoDeCampo === ETipoDeCampo.TEXTO_LONGO && (
                            <Grid item xs={12} sm={12}>
                              <TextField
                                id={String(field.idCampo)}
                                label={
                                  field.obrigatorio
                                    ? `${field.nomeCampo} *`
                                    : field.nomeCampo
                                }
                                helper={
                                  field.textoDeAjuda
                                    ? field.textoDeAjuda
                                    : undefined
                                }
                                name={stringCapitalize(
                                  field.nomeCampo,
                                  field.idCampo,
                                )}
                                disabled={!permissionEdit}
                                multiline
                                rows={3}
                              />
                            </Grid>
                          )}
                        {(field.disponivelParaLead || isInternal) &&
                          field.idTipoDeCampo ===
                            ETipoDeCampo.BARRA_DE_PROGRESSO && (
                            <Grid item xs={12} sm={12}>
                              <RatingForm field={field} />
                            </Grid>
                          )}
                      </>
                    )}
                  </Fragment>
                ))}

                {item.idSecaoPredefinida === 1 &&
                  offerSelected?.controleVagas?.visivelNoForm && (
                    <Grid item xs={12}>
                      {offerSelected.controleVagas.tipoControleDeVaga !==
                        null &&
                      offerSelected.controleVagas.valorControleDeVaga !==
                        null ? (
                        <Alert
                          severity={
                            offerSelected.controleVagas.tipoControleDeVaga === 1
                              ? 'success'
                              : 'warning'
                          }>
                          {offerSelected.controleVagas.tipoControleDeVaga ===
                          1 ? (
                            'Sua vaga está garantida!'
                          ) : (
                            <Box display="flex">
                              <Typography>
                                Você está na lista de espera
                              </Typography>
                              <Box mx={2} display="flex">
                                <Divider orientation="vertical" flexItem />
                              </Box>
                              <Typography>
                                Posição:{' '}
                                {
                                  offerSelected.controleVagas
                                    .valorControleDeVaga
                                }
                              </Typography>
                            </Box>
                          )}
                        </Alert>
                      ) : (
                        <Alert
                          style={{alignItems: 'center'}}
                          severity={
                            offerSelected.controleVagas.possuiListaDeEspera
                              ? offerSelected.controleVagas
                                  .valorVagasRestante === 0
                                ? 'warning'
                                : 'info'
                              : offerSelected.controleVagas
                                  .valorVagasRestante === 0
                              ? 'error'
                              : 'info'
                          }>
                          <Box display="flex">
                            <Typography>
                              Vagas: {offerSelected.controleVagas.numeroDeVagas}
                            </Typography>
                            <Box mx={2} display="flex">
                              <Divider orientation="vertical" flexItem />
                            </Box>
                            <Typography>
                              Vagas restantes:{' '}
                              {offerSelected.controleVagas.valorVagasRestante}
                            </Typography>
                            {offerSelected.controleVagas.possuiListaDeEspera &&
                              offerSelected.controleVagas.valorVagasRestante ===
                                0 && (
                                <>
                                  <Box mx={2} display="flex">
                                    <Divider orientation="vertical" flexItem />
                                  </Box>
                                  <Typography>
                                    Pessoas em lista de espera:{' '}
                                    {
                                      offerSelected.controleVagas
                                        .valorListaDeEsperaAtual
                                    }
                                  </Typography>
                                </>
                              )}
                          </Box>
                        </Alert>
                      )}
                    </Grid>
                  )}
              </Fragment>
            ))}
            {termoLGPD.disponibilizarTermoDeAceiteParaLead && (
              <>
                <Grid
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center',
                  }}>
                  <Box marginBottom={3}>
                    <Typography variant="h5">
                      <strong>Termos e condições</strong>
                    </Typography>
                  </Box>
                  <TextField
                    id={'Termo'}
                    fullWidth
                    label={'Termo'}
                    name={'Termo'}
                    isError={isCheckboxError}
                    disabled={!permissionEdit}
                    helper={
                      isCheckboxError && !isEditing
                        ? 'Campo requerido.'
                        : undefined
                    }
                    multiline
                    rows={5}
                    InputProps={{readOnly: true}}
                    value={handleTermoLGDPTextFormatter(
                      termoLGPD.textoTermoLgpd,
                    )}
                  />
                  {!isEditing && (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'center'}>
                      <Checkbox
                        disabled={!permissionEdit}
                        name="23"
                        readOnly={isEditing}
                        isChecked={termoLGPD.concordaComTermosECondicoes}
                        onClick={() => {
                          setIsCheckboxError(false);
                          setIsTermoLGPDAtivo(
                            !termoLGPD.concordaComTermosECondicoes,
                          );
                        }}
                      />
                      <Typography variant="body1">
                        <strong>Concordo com os termos e condições</strong>
                      </Typography>
                    </Box>
                  )}

                  {isEditing && termoLGPD.disponibilizarTermoDeAceiteParaLead && (
                    <Box marginTop={2} marginBottom={1} width={170}>
                      <TextField
                        InputProps={{readOnly: true}}
                        fullWidth
                        name="data e hora"
                        label="Data e hora aceite"
                        value={handleDataFormat(dataHoraAceiteTermoLgpd)}
                      />
                    </Box>
                  )}
                </Grid>
              </>
            )}
            {!isInternal ? (
              <Grid item xs={12}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  marginTop={3}>
                  <ReCAPTCHA
                    sitekey="6Lc37DEbAAAAABy91EIWTie45JA9ddJ3SZ0Lk1ZB"
                    onChange={handleChangeRecaptcha}
                  />
                </Box>
                {isRecaptchaError ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    <Typography variant="body2" color="error">
                      Campo requerido
                    </Typography>
                  </Box>
                ) : (
                  <></>
                )}
              </Grid>
            ) : (
              <Box padding={3} />
            )}
            {!isInternal && personalization.instrucaoParaRodapeFormulario && (
              <Box
                width="100%"
                textAlign="center"
                dangerouslySetInnerHTML={{
                  __html:
                    personalization.instrucaoParaRodapeFormulario.replaceAll(
                      'class',
                      'className',
                    ),
                }}
              />
            )}
            {!offerSelected && (
              <Grid item xs={12}>
                <Alert severity="warning">Selecione uma oferta.</Alert>
              </Grid>
            )}
            {isInitialOfferChanged &&
              initialOffer?.controleVagas &&
              (initialOffer.controleVagas.tipoControleDeVaga === 1 ||
                initialOffer.controleVagas.tipoControleDeVaga === 2) && (
                <Grid item xs={12}>
                  <Alert severity="warning">
                    Você está alterando a oferta selecionada e perderá a sua
                    vaga!
                  </Alert>
                </Grid>
              )}
            {!offerSelected?.controleVagas?.possuiListaDeEspera &&
              (offerSelected?.controleVagas?.numeroDeVagas === 0 ||
                offerSelected?.controleVagas?.valorVagasRestante === 0) && (
                <Grid item xs={12}>
                  <Alert severity="error">
                    A oferta atual não possui vagas disponíveis.
                  </Alert>
                </Grid>
              )}
            {permissionEdit && (
              <Grid item xs={12}>
                <Button
                  disabled={
                    !offerSelected ||
                    (!offerSelected?.controleVagas?.possuiListaDeEspera &&
                      (offerSelected?.controleVagas?.numeroDeVagas === 0 ||
                        offerSelected?.controleVagas?.valorVagasRestante === 0))
                  }
                  fullWidth
                  variant="contained"
                  color="primary"
                  type="submit">
                  Confirmar
                </Button>
              </Grid>
            )}
          </Grid>
        </Form>
      </Box>
    </Box>
  );
};
